import {useLatestDocumentVersion} from '@hconnect/common/hooks/legal/useLegalDocumentsVersions'
import {LegalDocumentType, TermsAndPrivacyModal} from '@hconnect/uikit'
import {ReactComponent as HConnectLogo} from '@hconnect/uikit/src/common/assets/HConnectLogo_colored.svg'
import {Box} from '@mui/material'
import qs from 'query-string'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router'

export const MobileLegalAcceptancePage = () => {
  const {t, i18n} = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const queryParams = qs.parse(location.search)
  const country = queryParams['country'] as string | undefined
  const preferredLanguage = queryParams['language'] as string | undefined
  const redirectUrl = queryParams['terms_redirect_uri'] as string
  const paddingTop = queryParams['paddingTop']
    ? parseInt(queryParams['paddingTop'] as string)
    : undefined
  const paddingBottom = queryParams['paddingBottom']
    ? parseInt(queryParams['paddingBottom'] as string)
    : undefined

  useEffect(() => {
    void i18n.changeLanguage(preferredLanguage)
  }, [preferredLanguage, i18n])

  const latestTcQueryInfo = useLatestDocumentVersion('tc', country)
  const latestDppQueryInfo = useLatestDocumentVersion('dpp', country)

  if (
    !country ||
    (!latestTcQueryInfo?.isLoading && !latestTcQueryInfo?.lastAvailableDocumentVersion) ||
    (!latestDppQueryInfo?.isLoading && !latestDppQueryInfo?.lastAvailableDocumentVersion)
  ) {
    return (
      <Box mt={2} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        {!country && (
          <Box>
            <h1>{t('legal.missingCountryQueryParameter')}</h1>
          </Box>
        )}
        {!latestTcQueryInfo?.lastAvailableDocumentVersion && (
          <Box>
            <h1>{t('legal.noTcVersion')}</h1>
          </Box>
        )}
        {!latestDppQueryInfo?.lastAvailableDocumentVersion && (
          <Box>
            <h1>{t('legal.noDpVersion')}</h1>
          </Box>
        )}
      </Box>
    )
  }

  const syncQueryParams = (languageCode: string) => {
    void i18n.changeLanguage(languageCode)
    queryParams['language'] = languageCode
    const queryString = qs.stringify(queryParams)
    history.push({search: queryString})
  }

  return (
    <TermsAndPrivacyModal
      showFooter={true}
      logo={HConnectLogo}
      documentType={LegalDocumentType.TERMS}
      onLanguageChange={syncQueryParams}
      onAccept={() => {
        window.location.href = redirectUrl
          ? `${redirectUrl}?tc=${latestTcQueryInfo?.lastAvailableDocumentVersion?.version}&dpp=${latestDppQueryInfo?.lastAvailableDocumentVersion?.version}`
          : 'no-url'
      }}
      country={country}
      preferredLanguage={preferredLanguage}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      hideAcceptanceTitle={false}
    />
  )
}
