import type {LegalDocumentsType} from '@hconnect/common/hooks/legal/types'
import {useLegalDocument} from '@hconnect/common/hooks/legal/useLegalDocuments'
import {useLatestDocumentVersion} from '@hconnect/common/hooks/legal/useLegalDocumentsVersions'
import {LegalDocumentRenderer, useTranslation, LanguagePicker} from '@hconnect/uikit'
import {Box, CircularProgress} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useParams, Link} from 'react-router-dom'

import {Page} from '../Components/Page'

type LegalDocumentRouteParams = {
  type: LegalDocumentsType
  country: string
  language?: string
}

export const LegalDocumentPage: React.FC = () => {
  // there is actually no language path parameter in the router defined.
  const {type, country, language} = useParams<LegalDocumentRouteParams>()

  const {t, i18n} = useTranslation()

  useEffect(() => {
    void i18n.changeLanguage(country)
  }, [country, i18n])

  const [requestedDocumentLanguage, setRequestedDocumentLanguage] = useState<string | null>(
    language || null
  )
  const latestVersionQueryInfo = useLatestDocumentVersion(type, country)
  const {queryInfo, language: legalDocumentLanguage} = useLegalDocument(
    type,
    country,
    requestedDocumentLanguage
  )

  if (queryInfo.isLoading || latestVersionQueryInfo?.isLoading) {
    return (
      <Box justifyContent="center" alignItems="center" display="flex" height="90vh">
        <CircularProgress />
      </Box>
    )
  }
  if (!queryInfo.data) {
    // TODO: gracefully handle
    return (
      <Box
        onClick={() => {
          void queryInfo.refetch()
        }}
      >
        oops something went wrong
      </Box>
    )
  }

  const availableLanguages =
    latestVersionQueryInfo?.lastAvailableDocumentVersion?.availableLanguages

  return (
    <Page
      header={t(`countries.${country.toUpperCase()}`)}
      subHeader={t(`legalDocumentPages.${type}.documentSubheader`)}
    >
      <Box p={2} pb={0} display="flex" justifyContent="flex-end">
        {legalDocumentLanguage && availableLanguages && availableLanguages.length > 1 && (
          <LanguagePicker
            availableLanguages={availableLanguages}
            currentLanguage={legalDocumentLanguage}
            country={country}
            selectLanguage={(languageCode) => {
              void i18n.changeLanguage(languageCode)
              setRequestedDocumentLanguage(languageCode)
            }}
          />
        )}
      </Box>
      <Box pb={2}>
        <LegalDocumentRenderer body={queryInfo.data} />
      </Box>
      <Box p={2}>
        <Link to={'/ossattribution'}>{t('open_oss_licenses')}</Link>
      </Box>
    </Page>
  )
}
