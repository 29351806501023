import {useTranslation} from '@hconnect/uikit'
import {Box} from '@mui/material'
import React from 'react'

import {LicensesList} from '../Components/LicensesList'
import {Page} from '../Components/Page'

export const LicensesPage: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Page header={t('LicensesPage.title')}>
      <Box>
        <LicensesList />
      </Box>
    </Page>
  )
}
