import type {LegalDocumentsType} from '@hconnect/common/hooks/legal/types'
import {useTranslation} from '@hconnect/uikit'
import {Box, Typography} from '@mui/material'
import React from 'react'
import {Link} from 'react-router-dom'
import {makeStyles} from 'tss-react/mui'

import {Page} from '../Components/Page'

const useStyles = makeStyles()((theme) => {
  return {
    policiesLinkContainer: {
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        flexDirection: 'row'
      }
    },
    documentContainer: {
      textDecoration: 'none',
      color: '#002E4D',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(2)
    },
    link: {
      textDecoration: 'none',
      color: '#29aaff'
    }
  }
})

export const LandingPage = () => {
  const {t} = useTranslation()
  const {classes} = useStyles()

  const availableDocuments: LegalDocumentsType[] = ['dpp', 'tc', 'imprint', 'ac']

  return (
    <Page header={t('landingPage.policies')}>
      <Box className={classes.policiesLinkContainer}>
        {availableDocuments.map((documentType: LegalDocumentsType) => {
          return (
            <Box key={documentType} className={classes.documentContainer}>
              <Typography style={{fontSize: '18px', fontWeight: 700, lineHeight: '1.666em'}}>
                {t(`landingPage.documentHeader.${documentType}`)}
              </Typography>
              <Typography style={{fontSize: '14px'}}>
                {t(`landingPage.documentDescription.${documentType}`)}
              </Typography>
              <Link
                key={documentType}
                className={classes.link}
                to={`/policies/${documentType}`}
                data-test-id={`landing-link-${documentType}`}
              >
                <Typography style={{fontSize: '14px'}}>
                  {t(`landingPage.documentLink.${documentType}`)}
                </Typography>
              </Link>
            </Box>
          )
        })}
      </Box>
    </Page>
  )
}
