import type {LegalDocumentsType} from '@hconnect/common/hooks/legal/types'
import {useLegalDocument} from '@hconnect/common/hooks/legal/useLegalDocuments'
import {LegalDocumentRenderer} from '@hconnect/uikit'
import {CircularProgress} from '@mui/material'
import qs from 'query-string'
import React from 'react'
import {useParams} from 'react-router'

export const MobileHeadlessDocumentPage = () => {
  const {type} = useParams<{
    type: LegalDocumentsType
  }>()

  const {search} = window.location
  const queryParams = qs.parse(search)
  const country = queryParams['country'] as string | undefined
  const preferredLanguage = queryParams['language'] as string | undefined

  return <HeadlessDocument type={type} country={country} preferredLanguage={preferredLanguage} />
}

const HeadlessDocument = ({type, country, preferredLanguage}) => {
  const {queryInfo} = useLegalDocument(type, country, preferredLanguage)

  if (queryInfo.isLoading) {
    return <CircularProgress />
  }

  return <LegalDocumentRenderer body={queryInfo.data ? queryInfo.data : ''} />
}
