import {ErrorPage} from '@hconnect/authenticator/src/Pages/ErrorPage'
import {useBreakPoints} from '@hconnect/uikit'
import LogoHConnect from '@hconnect/uikit/src/common/assets/LogoHConnectNew.svg'
import {HProduceAccountDropdown, Shell} from '@hconnect/uikit/src/lib2'
import {Box} from '@mui/material'
import React, {useEffect} from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {Link, matchPath, useHistory} from 'react-router-dom'

import {Router} from './Router'
import {routes} from './routes'

export const App: React.FC = () => {
  const {t} = useTranslation()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const history = useHistory()

  useEffect(() => {
    document.title = t('legal.title')
  })

  const hideShell = [routes.MobileLegalAcceptancePage, routes.MobileHeadlessDocumentPage].reduce(
    (acc, route) => {
      return matchPath(window.location.pathname, {path: route, strict: true}) !== null ? true : acc
    },
    false
  )

  return (
    <ErrorBoundary FallbackComponent={({error}) => <ErrorPage error={error.message} />}>
      {hideShell ? (
        <Box style={{backgroundColor: '#FFFFFF'}}>
          <Router />
        </Box>
      ) : (
        <Shell
          compact={false}
          isResponsive
          boxed={false}
          onDark={false}
          showScrollToTop={false}
          stickyFooter={!isMobile}
          zIndex={1002}
          header={
            <Box style={{width: '100%'}}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <Link to={routes.LandingPage} data-test-id="logo-hconnect-link">
                  <img
                    src={LogoHConnect}
                    style={{maxWidth: '112px', height: '40px'}}
                    alt="HConnect"
                    data-test-id="logo-hconnect"
                  />
                </Link>
                <HProduceAccountDropdown
                  imprintText={t('landingPage.documentHeader.imprint')}
                  tcText={t('landingPage.documentHeader.tc')}
                  dppText={t('landingPage.documentHeader.dpp')}
                  acText={t('landingPage.documentHeader.ac')}
                  osSoftwareAttributionText={t('open_oss_licenses')}
                  actions={{
                    imprint: () => {
                      history.push('/policies/imprint/')
                    },
                    tc: () => {
                      history.push('/policies/tc/')
                    },
                    dpp: () => {
                      history.push('/policies/dpp/')
                    },
                    ac: () => {
                      history.push('/policies/ac/')
                    },
                    osSoftwareAttribution: () => {
                      history.push(routes.LicensesPage)
                    }
                  }}
                />
              </Box>
            </Box>
          }
        >
          <Router />
        </Shell>
      )}
    </ErrorBoundary>
  )
}
